'use client'
import React from 'react'


const Loader = () => {
  return (
    <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', zIndex: '9999999', background: '#fff', top: 0 }} className="example">
      Loader ...
    </div>
  )
}

export default Loader